const types = {
/**---------  Auth types -------------------------------- */
    CREATE_ACCOUNT_SUCCESS:"CREATE_ACCOUNT_SUCCESS",
    CREATE_ACCOUNT_FAILED:"CREATE_ACCOUNT_FAILED",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",
    AUTH_COMMON_ERROR:"AUTH_COMMON_ERROR",
    REQUESET_RESET_PASS_SUCCESS: "REQUESET_RESET_PASS_SUCCESS",
    REQUESET_RESET_PASS_FAILED: "REQUESET_RESET_PASS_FAILED",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILED: " RESET_PASSWORD_FAILED",
    LOGOUT: "LOGOUT",
    RESEND_VERIFICATION_LINK_SUCCESS :"RESEND_VERIFICATION_LINK",
    RESEND_VERIFICATION_LINK_FAILED:"RESEND_VERIFICATION_LINK_FAILED",
    OTP_REQUEST_SUCCESS:"OTP_REQUEST_SUCCESS",
    OTP_REQUEST_FAILED:"OTP_REQUEST_FAILED",
    OTP_VERIFY_SUCCESS:"OTP_VERIFY_SUCCESS",
    OTP_VERIFY_FAILED:"OTP_VERIFY_FAILED",
    CLEAR_SIGN_UP:"CLEAR_SIGN_UP",

  /*******user Types ***********************/
    GET_USER_SUCCESS: "GET_USER_SUCESS",
    GET_USER_FAILED: "GET_USER_FAILED",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
    USER_COMMON_ERROR: "USER_COMMON_ERROR",
    GET_SINGLE_USER_SUCCESS:"GET_SINGLE_USER_SUCCESS",
    GET_SINGLE_USER_FAILED:"GET_SINGLE_USER_FAILED",
    USER_CREATED_POSTS_SUCCESS:"USER_CREATED_POSTS_SUCCESS",
    USER_CREATED_POSTS_FAILED:"USER_CREATED_POSTS_FAILED",
    USERS_LIST_SUCCESS: "USERS_LIST_SUCCESS",
    USERS_LIST_FAILED:"USERS_LIST_FAILED",
    STEAMER_LIST_SUCCESS:"STEAMER_LIST_SUCCESS",
    STEAMER_LIST_FAILED:"STEAMER_LIST_FAILED",
    SEARCH_USER:"SEARCH_USER",
    SEARCH_USER_FAILED: "SEARCH_USER_FAILED" , 
    UPDATE_HEADER_SUCCESS:"UPDATE_HEADER_SUCCESS",
    UPDATE_HEADER_FAILED:"UPDATE_HEADER_FAILED",

  /******************************************** */

  /***********  text post types  **************************/
    CREATE_TEXT_SUCCESS: "CREATE_TEXT_SUCCESS " ,
    CREATE_TEXT_FAILED:"CREATE_TEXT_FAILED",
    GET_TEXT_SUCCESS:"GET_TEXT_SUCCESS",
    GET_TEXT_FAILED:"GET_TEXT_FAILED",
    GET_SINGLE_TEXT_SUCCESS:"GET_SINGLE_TEXT_SUCCESS",
    GET_SINGLE_TEXT_FAILED:"GET_SINGLE_TEXT_FAILED",
    EDIT_TEXT_POST_SUCCESS:"EDIT_TEXT_POST_SUCCESS",
    EDIT_TEXT_POST_FAILED:"EDIT_TEXT_POST_FAILED",
    TEXT_COMMON_ERROR:"TEXT_COMMON_ERROR",
    CLEAR_TEXT_CREATE:"CLEAR_TEXT_CREATE",
    TEXT_TEMP_DATA:"TEXT_TEMP_DATA",
  /***************************************************** */
  /*************  Link post types *****************************/
    CREATE_LINK_SUCCESS: "CREATE_LINK_SUCCESS " ,
    CREATE_LINK_FAILED:"CREATE_LINK_FAILED",
    GET_LINK_SUCCESS:"GET_LINK_SUCCESS",
    GET_LINK_FAILED:"GET_LINK_FAILED",
    LINK_COMMON_ERROR:"TEXT_COMMON_ERROR",
    EDIT_LINK_SUCCESS:"EDIT_LINK_SUCCESS",
    EDIT_LINK_FAILED:" EDIT_LINK_FAILED",
    CLEAR_LINK_CREATE:"CLEAR_LINK_CREATE",

  /*********************************************************** */
  /******************  visual Post type *******************************/
    CREATE_VISUAL_SUCCESS: 'CREATE_VISUAL_SUCCESS',
    CREATE_VISUAL_FAILED: 'CREATE_VISUAL_FAILED',
    GET_VISUAL_SUCCESS: 'GET_VISUAL_SUCCESS',
    GET_VISUAL_FAILED: 'GET_VISUAL_FAILED',
    VISUAL_COMMON_ERROR: ' VISUAL_COMMON_ERROR',
    EDIT_VISUAL_POST_SUCCESS: "EDIT_VISUAL_POST_SUCCESS",
    EDIT_VISUAL_POST_FAILED: "EDIT_VISUAL_POST_FAILED",
    CLEAR_VISUAL_CREATE: "CLEAR_VISUAL_CREATE",
/** ---------------------------------------------------------- */    

/******************** Post types *****************************************/
  GET_SINGLE_POST_SUCCESS:"GET_SINGLE_POST",
  GET_SINGLE_POST_FAILED:"GET_SINGLE_POST_FAILED",
  GET_BOARD_POST_SUCCESS:"GET_BOARD_POST_SUCCESS",
  GET_BOARD_POST_FAILED:"GET_BOARD_POST_FAILED",
  GET_SINGLE_BOARD_SUCCESS:"GET_SINGLE_BOARD_SUCCESS",
  GET_SINGLE_BOARD_FAILED:"GET_SINGLE_BOARD_FAILED",
  GET_DRAFT_POST_SUCCESS : "GET_DRAFT_POST_SUCCESS",
  GET_DRAFT_POST_FAILED: "GET_DRAFT_POST_FAILED",
  CHANGE_POST_STATUS_SUCCESS: "CHANGE_POST_STATUS_SUCCESS",
  CHANGE_POST_STATUS_FAILED: "CHANGE_POST_STATUS_FAILED", 
  COMMON_SINGLE_POST_ERROR:"COMMON_SINGLE_POST_ERROR" , 
  DISCOVER_POST:" DISCOVER_POST",
  DISCOVER_POST_ERROR:" DISCOVER_POST_ERROR",
  STREAM_POST_SUCCESS:"STREAM_POST_SUCCESS",
  STREAM_POST_ERROR:" STREAM_POST_ERROR",
/******************************************************************************* */
/*********************  video types  **********************************************/
  GET_VIDEO_POST_SUCCESS:"GET_VIDEO_POST_SUCCESS",
  GET_VIDEO_POST_FAILED:"GET_VIDEO_POST_FAILED",
  CREATE_VIDEO_SUCCESS:"CREATE_VIDEO_SUCCESS",
  CREATE_VIDEO_FAILED:"CREATE_VIDEO_FAILED",
  COMMOM_VIDEO_ERROR:"COMMOM_VIDEO_ERROR",
  UPLOAD_VIDEO_SUCCESS:"UPLOAD_VIDEO_SUCCESS",
  UPLOAD_VIDEO_FAILED:"UPLOAD_VIDEO_FAILED",
  EDIT_VIDEO_SUCCESS:"EDIT_VIDEO_SUCCESS",
  EDIT_VIDEO_FAILED:"EDIT_VIDEO_FAILED",
  LIVE_VIDEO_STARTED:"LIVE_VIDEO_STARTED",
  STOP_LIVE_VIDEO:"STOP_LIVE_VIDEO",
  CLEAR_VIDEO_CREATE:"CLEAR_VIDEO_CREATE",
  VIDEO_PLAYER_SUCCESS:"VIDEO_PLAYER_SUCCESS",
  CLEAR_VIDEO_PLAYER:"CLEAR_VIDEO_PLAYER",
  SHOW_VIDEO_PLAYER:"SHOW_VIDEO_PLAYER", 
  START_VIDEO_PLAYER:"START_VIDEO_PLAYER",
  STOP_VIDEO_PLAYER:"STOP_VIDEO_PLAYER",
  VIDEO_TIMESTAMP:"VIDEO_TIMESTAMP",
 
//----------------------------------------------------------------------------------//
/**********************    audio types ********************************************/
  GET_AUDIO_POST_SUCCESS:"GET_AUDIO_POST_SUCCESS",
  GET_AUDIO_POST_FAILED: "GET_AUDIO_POST_FAILED",
  CREATE_AUDIO_SINGLE_SUCCESS: "CREATE_AUDIO_SINGLE_SUCCESS",
  CREATE_AUDIO_SINGLE_FAILED : "CREATE_AUDIO_SINGLE_FAILED",
  COMMOM_AUDIO_ERROR: "COMMOM_VIDEO_ERROR",
  EDIT_AUDIO_SUCCESS:"EDIT_AUDIO_SUCCESS",
  EDIT_AUDIO_FAILED:"EDIT_AUDIO_FAILED",
  CLEAR_AUDIO_CREATE:"CLEAR_AUDIO_CREATE",
  LIVE_AUDIO_STARTED : "LIVE_AUDIO_STARTED",
  STOP_LIVE_AUDIO : "STOP_LIVE_AUDIO" , 
/************************************************************************************/


/************************************   Market-Place - Types  ************************************/
/*******************  shops *******************************/
  CREATE_SHOP_SUCCESS:"CREATE_SHOP_SUCCESS",
  CREATE_SHOP_FAILED:"CREATE_SHOP_FAILED",
  GET_SHOP_SUCCESS:"GET_SHOP_SUCCESS",
  GET_SHOP_FAILED:"GET_SHOP_FAILED",
  SHOP_CAT_SUCCESS:"SHOP_CAT_SUCCESS",
  SHOP_CAT_FAILED:"SHOP_CAT_FAILED",
  GET_MY_SHOP_SUCCESS:"GET_MY_SHOP_SUCCESS",
  GET_MY_SHOP_FAILED:"GET_MY_SHOP_FAILED",
  GET_SINGLE_SHOP_SUCCESS:"GET_SINGLE_SHOP_SUCCESS",
  GET_SINGLE_SHOP_FAILED:"GET_SINGLE_SHOP_FAILED",
  CHANGE_SHOP_STATUS_SUCCESS:"CHANGE_SHOP_STATUS_SUCCESS",
  CHANGE_SHOP_STATUS_FAILED:"CHANGE_SHOP_STATUS_FAILED",
  EDIT_SHOP_SUCCESS:"EDIT_SHOP_SUCCESS",
  EDIT_SHOP_FAILED: "EDIT_SHOP_FAILED",
  DELETE_SHOP_SUCCESS: "DELETE_SHOP_SUCCESS",
  DELETE_SHOP_FAILED: "DELETE_SHOP_FAILED",
  SHOP_COMMON_ERROR:"SHOP_COMMON_ERROR",
/************************************************************ */
/******************   Products types  ****************************/
  CREATE_PRODUCT_SUCCESS:"CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_FAILED:"CREATE_PRODUCT_FAILED",
  EDIT_PRODUCT_SUCCESS:"EDIT_PRODUCT_SUCCESS",
  EDIT_PRODUCT_FAILED:"EDIT_PRODUCT_FAILED",
  EDIT_PRODUCT_IMAGES_SUCCESS:" EDIT_PRODUCT_IMAGES",
  EDIT_PRODUCT_IMAGES_FAILED:"EDIT_PRODUCT_FAILED",
  GET_PRODUCTS_LIST_SUCCESS:"GET_PRODUCTS_LIST_SUCCESS",
  GET_PRODUCTS_LIST_FAILED:"GET_PRODUCTS_LIST_FAILED",
  GET_SHOP_PRODUCTS_SUCCESS:"GET_SHOP_PRODUCTS_SUCCESS",
  GET_SHOP_PRODUCTS_FAILED:"GET_SHOP_PRODUCTS_FAILED",
  PRODUCTS_COMMON_ERROR:"PRODUCTS_COMMON_ERROR",
  GET_SINGLE_PRODUCTS_SUCCESS:"GET_SINGLE_PRODUCTS_SUCCESS",
  GET_SINGLE_PRODUCTS_FAILED:"GET_SINGLE_PRODUCTS_FAILED",
  CHANGE_PRODUCT_STATUS_SUCCESS:"CHANGE_PRODUCT_STATUS_SUCCESS",
  CHANGE_PRODUCT_STATUS_FAILED:"CHANGE_PRODUCT_STATUS_FAILED",
  USER_PRODUCTS_LIST:"USER_PRODUCTS_LIST",
  USER_PRODUCTS_LIST_ERROR:"USER_PRODUCTS_LIST_ERROR",

/******************   Cart - types ********************************* */  
  ADD_TO_CART_SUCCESS:"ADD_TO_CART_SUCCESS",
  ADD_TO_CART_FAILED:"ADD_TO_CART_FAILED",
  GET_CART_ITEMS_SUCCESS:"GET_CART_ITEMS_SUCCESS",
  GET_CART_ITEMS_FAILED:"GET_CART_ITEMS_FAILED",
  REMOVE_CART_ITEM_SUCCESS:"REMOVE_CART_ITEM_SUCCESS",
  REMOVE_CART_ITEM_FAILED:"REMOVE_CART_ITEM_FAILED",
  CLEAR_CART_SUCCESS:"CLEAR_CART_SUCCESS",
  CLEAR_CART_FAILED:"CLEAR_CART_FAILED",
  CART_COMMON_ERROR:" CART_COMMON_ERROR",



/***************************   post like - comment - notification types -- socket **************/
  LIKE_POST:"LIKE_POST",
  CREATE_POST_COMMENT:"CREATE_POST_COMMENT",
  ADD_LIKE_COMMENT : "ADD_LIKE_COMMENT",
  DELETE_COMMENT: "DELETE_COMMENT",
  GET_NOTIFICATION:"GET_NOTIFICATION" ,
  GET_USER_NOTIFICATION:"GET_USER_NOTIFICATION",

/*************************************************************************************** */  
  SEARCH_SUCCESS:"SEARCH_SUCCESS",
  SEARCH_FAILED:"SEARCH_FAILED",
  SEARCH_COMMON_ERROR:"SEARCH_COMMON_ERROR",

  /** ------ Messenger ------- */
  LOAD_CHAT_MESSAGE_SUCCESS:"LOAD_CHAT_MESSAGE_SUCCESS",
  LOAD_CHAT_MESSAGE_FAILED:"LOAD_CHAT_MESSAGE_FAILED",
  INVITED_USER_SUCCESS: 'INVITED_USER_SUCCESS',
  INVITED_USER_FAILED: 'INVITED_USER_FAILED',
  GET_CHAT_USERS_SUCCESS: 'GET_CHAT_USERS_SUCCESS',
  SET_CURRENT_CHAT_USER: 'SET_CURRENT_CHAT_USER',
  GET_CHAT_USERS_FAILED: 'GET_CHAT_USERS_FAILED',
  NEW_MESSAGE: 'NEW_MESSAGE',
  UPDATE_MESSAGES:'UPDATE_MESSAGES',
  UNSEEN_COUNT:'UNSEEN_COUNT',
  UPDATE_CHAT_USERS:"UPDATE_CHAT_USERS",
  SENDER_UNSEEN_COUNT:"SENDER_UNSEEN_COUNT",

/****************************************   order types ************************************************** */
  ORDER_CREATED_SUCCESS:"ORDER_CREATED_SUCCESS",
  ORDER_CREATED_FALSE:"ORDER_CREATED_FALSE",
  ORDER_COMMON_ERROR:"ORDER_COMMON_ERROR",
  CHANGE_ORDER_STATUS_SUCCESS:"CHANGE_ORDER_STATUS_SUCCESS",
  CHANGE_ORDER_STATUS_ERROR:"CHANGE_ORDER_STATUS_ERROR",
  GET_BUY_ORDER_SUCCESS:"GET_BUY_ORDER_SUCCESS",
  GET_BUY_ORDER_FAILED:"GET_BUY_ORDER_FAILED",
  GET_SELLED_ORDER_SUCCESS:"GET_SELLED_ORDER_SUCCESS",
  GET_SELLED_ORDER_FAILED:"GET_SELLED_ORDER_FAILED",



  /*************************************  post clear  ******************************************/

  CLEAR_POST_DATA:"CLEAR_POST_DATA",
  CLEAR_POSTS : "CLEAR_POSTS",

  /****************************************   Live Video Post  **********************************/
  SCHEDULE_LIVE_POST_SUCCESS:"SCHEDULE_LIVE_POST_SUCCESS",
  SCHEDULE_LIVE_POST_FAILED:"SCHEDULE_LIVE_POST_FAILED",
  GET_LIVE_VIDEO_POST_SUCCESS:"GET_LIVE_VIDEO_POST_SUCCESS",
  GET_LIVE_VIDEO_POST_FAILED:"GET_LIVE_VIDEO_POST_FAILED",
  CREATE_LIVE_VIDEO_POST:"CREATE_LIVE_VIDEO_POST",
  CREATE_LIVE_VIDEO_POST_ERROR:"CREATE_LIVE_VIDEO_POST_ERROR",
  EDIT_LIVE_VIDEO_POST_SUCEESS: "EDIT_LIVE_VIDEO_POST_SUCEESS",
  EDIT_LIVE_VIDEO_POST_FAILED:"EDIT_LIVE_VIDEO_POST_FAILED",
  COMMON_LIVE_POST_ERROR:"COMMON_LIVE_POST_ERROR",
  CLEAR_LIVE_CREATE:"CLEAR_LIVE_CREATE",
  CLEAR_LIVE_COMMENTS:"CLEAR_LIVE_COMMENTS",
  CLEAR_LIVE_POST:"CLEAR_LIVE_POST",

  /******************************   Notification **************************************************/
  GET_NOTIFICATION_LIST:"GET_NOTIFICATION_LIST",
  GET_NOTIFICATION_ERROR:"GET_NOTIFICATION_ERROR",
  /************************  Audio Player ****************************/
  AUDIO_LIST:"AUDIO_LIST",
  CLEAR_AUDIO_LIST:"CLEAR_AUDIO_LIST" , 

  /***************************  schedules *******************************************************/

  CREATE_SCHEDULE:"CREATE_SCHEDULE",
  GET_SCHEDULE:"GET_SCHEDULE",
  CREATE_SCHEDULE_ERROR:"CREATE_SCHEDULE_ERROR",
  GET_SCHEDULE_ERROR:"GET_SCHEDULE_ERROR",
  UPDATE_SCHEDULE:"UPDATE_SCHEDULE",
  UPDATE_SCHEDULE_ERROR:"UPDATE_SCHEDULE_ERROR",
  SCHEDULE_COMMON_ERROR:"SCHEDULE_COMMON_ERROR",
  DELETE_SCHEDULE_SUCCESS:"DELETE_SCHEDULE_SUCCESS",
  DELETE_SCHEDULE_ERROR:"DELETE_SCHEDULE_ERROR",
  GET_TIME_SLOTS:"GET_TIME_SLOTS",
  GET_TIME_SLOTS_ERROR:"GET_TIME_SLOTS_ERROR" , 
  BOOK_SCHEDULE:"BOOK_SCHEDULE",
  BOOK_SCHEDULE_ERROR:"BOOK_SCHEDULE_ERROR",
  GET_EVENT:"GET_EVENT",
  CREATE_EVENT_ERROR:"CREATE_EVENT_ERROR",
  GET_EVENT_ERROR:"GET_EVENT_ERROR",
  CLEAR_SCHEDULES:'CLEAR_SCHEDULES',
  CREATE_EVENT:'CREATE_EVENT',
  CREATE_EVENT_FAILED:'CREATE_EVENT_FAILED',
  GET_MEETINGS:"GET_MEETINGS",
  GET_MEETINGS_ERROR:"GET_MEETINGS_ERROR"

}  


module.exports = types;