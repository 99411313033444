import React, { useState, useEffect } from "react";
import { useNavigate , useParams} from "react-router-dom";
import { Form, Button, Row, Col,Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import * as Auth from "../../helpers/auth";
import {getEvents} from "../../redux/actions/schedule.action";
import BookingCalendar from "./BookingModel";

function EventsList() {
    const { id } = useParams();
    const [ showMeeting , setShowMeeting] = useState(false);
    const [events , setEvents ] = useState([]);
    const [eventLoading , setEventLoading] = useState(false);
    const [selectedEvent , setSelectedEvent] = useState('');
    const [ showEventModel , setShowEventModel ] = useState(false);
    const [creater , setCreater] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const scheduleState = useSelector(state => state.schedule);


    useEffect(()=>{
        getUserEvents()
        setEventLoading(true);
    },[])



    useEffect(()=>{
    if(scheduleState && scheduleState.userEvents){
        if(scheduleState.userEvents && scheduleState.userEvents.schedules){
            setEvents(scheduleState.userEvents.schedules)
            setEventLoading(false);
        }
        if(scheduleState.userEvents && scheduleState.userEvents.creater){
            setCreater(scheduleState.userEvents.creater)
        }
        
    }
    if(scheduleState && scheduleState.userEventError){
        setEventLoading(false);
    }
    },[scheduleState])



    const getUserEvents = () =>{
        const userData = Auth.decodedLoggedUser();
        dispatch(getEvents({userId:id}))
    }

    const handleCloseEventModel = () =>{
        setShowEventModel(false)
    }

    const handleSelectEvent = (e) =>{
        const {id} = e.target; 
        setSelectedEvent(id)
    }

    const handleAvailability = () =>{
        if(selectedEvent !== ''){
            setShowMeeting(true)
            
        }else{
            toast.error('Please select a event.')
        }
    }

    return (
        <>
            {!showMeeting && 
            <div className="p-5 container">
                <Row className="justify-content-center">
                <Col sm={12} lg={8}>
                    <Row>
                    <Col sm={6}>
                        <Button variant="primary" onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </Col>
                    </Row>
                    <div className="custom--add--times event--form">
                    {(eventLoading)&&<Spinner></Spinner>}
                    {(!eventLoading && events.length > 0)&&
                    <>
                        {creater && creater.first && <h3>{`${creater.first} ${creater.last}`}</h3>}
                        <Form>
                        {events.map((meeting , index) => (
                            <Form.Check 
                            key={index}
                            type="radio"
                            label={<span>{meeting.name} <small>{meeting.duration} min</small></span>}
                            id={meeting.id}
                            name="time-radio"
                            onChange={handleSelectEvent}
                            />
                        ))}
                        </Form>
                        <Button className="mt-3" variant="primary" onClick={handleAvailability}>See available times</Button>
                    </>
                    }
                    </div>
                </Col>
                </Row>
            </div>
            }
            {showMeeting && <BookingCalendar event={events.find((meeting) => Number(meeting.id) === Number(selectedEvent))} creater = {creater} hideAvailability = {()=>setShowMeeting(false)}/>}
        </> 
    );
}

export default EventsList;
